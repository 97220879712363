export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "ACTIONS": {
          "PAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
          "SERACHBYFOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por Folio"])}
        }
      }
    }
  })
}
