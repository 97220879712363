<template>
  <div v-if="infoCashUser.hasOpendCashRegister">
    <ModuleMaster 
        :requireSearchField="true"
        :requireAddButton="false"
        :requirePaginator="true"
        :labels="labels"
        :numItemsPaginator="totalRecords"
        :selectedPage="selectedPage"
        :itemsPerPage="itemsPerPage"
        :fnCallbackChangePage="handleChangePage"
        :fnCallbackSearchItems="searchItems"
    >
        <template v-slot:extraFilters>
            <div class="d-flex align-items-center position-relative my-1" style="padding-left: 16px;">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                        <inline-svg src="media/icons/duotune/general/gen021.svg" />
                    </span>
                    <input type="text" v-model="searchFolio" class="form-control form-control-solid w-250px ps-15" :placeholder="$t('ACTIONS.SERACHBYFOLIO')"/>
                </div>        
        </template>
        <Datatable :headers="headers" :requireActions="true" :hover="true" :bodySemibold="true">
            <Datarow 
                v-for="row in renderRows" 
                v-bind:key="row.ID" 
                :dataRow="row" 
                :showCrudActions="true"
            >
                <div 
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                    data-bs-toggle="tooltip" 
                    data-bs-placement="bottom" 
                    :title="$t('ACTIONS.PAY')" 
                    @click="handleActionClick('P', row.ID)"
                >
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="media/icons/duotune/finance/fin003.svg" />
                    </span>
                </div>
            </Datarow>
        </Datatable>
    </ModuleMaster>
    </div>
<!-- <div v-else>
    <br/> -->
    <div v-else>
    <NoDataMessage :message="'Usted no tiene cajas abiertas. Habrá una caja para realizar cobros'"></NoDataMessage>
    </div>
<!-- </div>s -->
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import ModuleMaster from "../../components/c-module-master/module-master.vue";
import Datatable from "../../components/c-datatable/Datatable.vue";
import Datarow from "../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../common/formaterToDatatable";
import SwalMessageService from "@/core/services/SwalMessageService";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { getModule } from "vuex-module-decorators";
import NoDataMessage from "@/components/shared/NoDataMessage.vue";
import CashModule from "@/store/modules/sales copy/modules/cash";
import { translate } from "@/core/plugins/i18n";

export default defineComponent({
    components: {
        ModuleMaster,
        Datatable,
        Datarow,
        NoDataMessage,
        //CrudActions
    },
    setup() {
        //BASICS
        const controller = "Sales";
        const messageService = new SwalMessageService();
        const moduleCash = getModule(CashModule);
        const router = useRouter();
        const labels = reactive({ placeHolderToFieldSearch: "Buscar por Nombre/RFC", titleNewCrudAction: "" });
        let totalItems = ref(0);
        let currentPage = ref(1);
        let itemsPage = ref(10);
        let records = ref([]);
        let textSearch = ref("");
        let searchFolio = ref("");

        moduleCash.GET_INFO_CASH();

        const configTable = reactive([
              { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false, Bold: false }
            , { PropName: "invoince", HeadLabel: "Folio", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "address", HeadLabel: "Dirección", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "saleTypeName", HeadLabel: "Tipo de Venta", Type: "text", VisibleInGrid: true, Bold: true }
            , { PropName: "subTotal", HeadLabel: "Subtotal", Type: "moneda", VisibleInGrid: true, Bold: true }
            , { PropName: "tax", HeadLabel: "Impuesto", Type: "moneda", VisibleInGrid: true, Bold: true }
            , { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true, Bold: true }
            , { PropName: "fechaCreacion", HeadLabel: "Fecha Creación", Type: "text", VisibleInGrid: true, Bold: true }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            await getPagedItems();
        });

        onMounted(() => {
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESPLAY'), [translate('BREADCRUMB.MODULES')]);
        });

        //VARIABLES COMPUTADAS
        const totalRecords = computed(() => totalItems.value);
        const selectedPage = computed(() => currentPage.value);
        const itemsPerPage = computed(() => itemsPage.value);
        const headers = computed(() => configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel));
        const renderRows = computed(() => formatToDatatable(records.value, configTable, "isActive"));

        watch(searchFolio, async(value) => {
            await getPagedItems();
        });

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const searchItems = async(text) => {
            currentPage.value = 1;
            textSearch.value = text;
            await getPagedItems();
        }

        const getPagedItems = async() => {
            let criteriaFolio = '';
            let criteriaText = '';
            if(searchFolio.value) {
                let tmpFloat = parseFloat(searchFolio.value.trim());
                let tmpInt = parseInt(searchFolio.value.trim());
                criteriaFolio = (tmpFloat - tmpInt) == 0 ? `&Criteria.invoice=${searchFolio.value.trim()}` : '';
            }
            criteriaText = textSearch.value.trim() != '' ? `&Criteria.Name=${textSearch.value.trim()}` : '';
            let criteriaSearch = `Criteria.Active=true${criteriaText}${criteriaFolio}`;
            let url = `${controller}/GetListSaleToPay?currentPage=${currentPage.value}&PageSize=${itemsPage.value}&${criteriaSearch}&Criteria.IsPayed=false&Criteria.Active=true`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleActionClick = (action, saleId) => {
            switch(action) {
                case "P":
                    goToPayPage(saleId);
                    break;
            }
        }

        const goToPayPage = (saleId) => {
            let dataProps = `v1:${saleId}`;
            router.push({
                name: '/adminSalesPay/box/:C0',
                params: {
                    C0: btoa(dataProps)
                }
            });
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        const infoCashUser = computed(() => moduleCash.getUserInfoCash);

        return {
            //variables y constantes
            labels
            , searchFolio

            //varaibles computadas
            , headers
            , renderRows
            , totalRecords
            , selectedPage
            , itemsPerPage
            , infoCashUser

            //funciones
            , handleChangePage
            , searchItems
            , handleActionClick
        }
        
    },
})
</script>

<i18n>
{
    "es": {
        "ACTIONS": {
            "PAY": "Pagar",
            "SERACHBYFOLIO": "Buscar por Folio"
        }
    }
}
</i18n>
